<template>
  <div>
    <!-- <div class="bread-crumbs" style="margin-bottom: 10px">
      首页 &gt; 注册须知
    </div> -->
    <div class="title">
      <h4>注册须知</h4>
    </div>
    <div class="txt txt1">
      <p></p>
      <p><br /></p>
      <p
        style="
          white-space: normal;
          box-sizing: border-box;
          
          margin: 0px;
          padding: 0px;
          color: rgb(121, 121, 121);
          font-size: 16px;
          background-color: rgb(255, 255, 255);
        "
      >
        <span
          style="
            box-sizing: border-box;
            
            font-size: 14px;
            color: rgb(0, 0, 0);
          "
          >请登录大会网站：http://bjfs2020.meetingchina.org</span
        >
      </p>
      <p
        style="
          white-space: normal;
          box-sizing: border-box;
          
          margin: 0px;
          padding: 0px;
          color: rgb(121, 121, 121);
          font-size: 16px;
          background-color: rgb(255, 255, 255);
        "
      >
        <span
          style="
            box-sizing: border-box;
            
            font-size: 14px;
            color: rgb(0, 0, 0);
          "
          ><br />点击<a
            href="http://4424.meeting.so/msite/login/index/cn?u=u&amp;fromurl=/muser/main/index/cn&amp;from_url2=muser/main/index/cn"
            target="_self"
            style="color: rgb(54, 96, 146); text-decoration: underline"
            ><span
              style="
                box-sizing: border-box;
                
                font-size: 14px;
                color: rgb(54, 96, 146);
              "
              ><strong>个人代表登录</strong></span
            ></a
          ><strong>&nbsp;</strong>按钮，填</span
        ><span
          style="
            box-sizing: border-box;
            
            font-size: 14px;
            color: rgb(0, 0, 0);
          "
          >写完整的注册信息后请按照网站提示选择相对应的注册类型按步骤完成注册流程。</span
        ><br />
      </p>
      <p
        style="
          white-space: normal;
          box-sizing: border-box;
          
          margin: 0px;
          padding: 0px;
          color: rgb(121, 121, 121);
          font-size: 16px;
          background-color: rgb(255, 255, 255);
        "
      >
        <span
          style="
            box-sizing: border-box;
            
            font-size: 14px;
            color: rgb(0, 0, 0);
          "
          ><br />本次会议只接受网上在线注册，线上观看免注册费。</span
        >
      </p>
      <p><br /></p>
    </div>
  </div>
</template>

<script>
export default {
  name: "RegistrationGuide"
};
</script>

<style scoped>
.title {
  border-bottom: 1px solid #e5f3fc;
  padding: 18px;
  margin-top: 22px;
  position: relative;
}
.title > h4 {
  font-weight: bold;
  font-size: 20px;
  color: #000000;
  line-height: 1.4em;
  padding-left: 24px;
  position: relative;
  margin: 0;
}
.title > h4:after {
 content: "";
  position: absolute;
  left: -5px;
  top: 5px;
  width: 20px;
  height: 20px;
  background-color: #c10000;
}
p {
  margin: 0;
}
.txt {
  font-size: 16px;
  color: #797979;
  line-height: 1.5em;
  padding: 25px 30px;
}
.txt img {
  max-width: 100%;
}
</style>